import { Product } from "@prisma/client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppState } from "./index";

export const initState: {
  items: Array<Product["id"]>;
} = {
  items: [],
};

export const ToggleInWishlistThunkAction = createAsyncThunk<
  string[],
  string,
  {
    dispatch: AppDispatch;
    state: AppState;
  }
>("wishlist/toggle", async (arg, { getState }) => {
  const state = getState();
  const index = state.wishlist.items.findIndex((i) => i === arg);
  const fetchOpts: RequestInit = {
    body: arg,
  };
  if (index > -1) {
    fetchOpts.method = "DELETE";
  } else {
    fetchOpts.method = "POST";
  }
  const resp = await fetch("/api/data/wishlist", fetchOpts);
  if (!resp.ok) {
    console.error("Problem updating wishlist");
    return [];
  }
  return await resp.json();
});

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: initState,
  reducers: {
    toggleInWishlist: (
      state,
      { payload: id }: PayloadAction<Product["id"]>
    ) => {
      const index = state.items.findIndex((i) => i === id);
      if (index > -1) {
        state.items.splice(index, 1);
      } else {
        state.items.push(id);
      }
    },
    addToWishlist: (state, { payload: id }: PayloadAction<Product["id"]>) => {
      !state.items.includes(id) && state.items.push(id);
    },
    removeFromWishlist: (
      state,
      { payload: id }: PayloadAction<Product["id"]>
    ) => {
      const index = state.items.findIndex((i) => i === id);
      if (index > -1) state.items.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ToggleInWishlistThunkAction.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

export const WishlistActions = wishlistSlice.actions;

export default wishlistSlice.reducer;
