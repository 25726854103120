import { Link, LinkProps, useMediaQuery } from "@chakra-ui/react";
import NextLink from "next/link";
import { PropsWithChildren, useMemo } from "react";

export type NavLinkProps = PropsWithChildren<
  {
    label?: string;
    href: string;
    icon?: JSX.Element;
    disabled?: boolean;
  } & LinkProps
>;

function NavLink({
  children,
  disabled,
  href,
  icon,
  label,
  ...linkProps
}: NavLinkProps) {
  const [showLabel] = useMediaQuery("(min-width: 600)");

  if (!children && !label && !icon) {
    throw new Error("Please provide either a label, child component or icon!");
  }

  const linkElement = useMemo(
    () => (
      <Link
        _focus={{
          boxShadow: "none",
        }}
        {...linkProps}
      >
        {icon}
        {(!icon || showLabel) && label}
        {children}
      </Link>
    ),
    [children, icon, label, linkProps, showLabel]
  );

  if (disabled) {
    return linkElement;
  }

  return (
    <NextLink href={href} passHref={!disabled}>
      {linkElement}
    </NextLink>
  );
}

export default NavLink;
