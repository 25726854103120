const ButtonTheme = {
  sizes: {
    md: {
      fontSize: "sm",
      paddingY: 2,
      paddingX: 4,
      height: "unset",
      whiteSpace: "normal",
    },
  },
};

export default ButtonTheme;
