import {
  Box,
  Button,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { Fragment, useMemo, useState } from "react";
import { useAppSelector } from "../../data/redux/hooks";
import { PriceTag } from "../PriceTag";

function ShoppingCartPopover() {
  const router = useRouter();
  const t = useTranslations("components.ShoppingCartPopover");
  const hasOrderData: boolean = useAppSelector(
    (s) =>
      s.orderProcess.delivery_address &&
      s.orderProcess.delivery_method &&
      s.orderProcess.payment_option &&
      s.orderProcess.payment_data
  );
  const [isOpen, setIsOpen] = useState(false);
  const shoppingCartItems = useAppSelector((s) => s.shoppingCart.items);
  const shoppingCartItemCount = shoppingCartItems.length;

  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  const goToDeliveryAddress = async () => {
    if (hasOrderData) {
      await router.push("/order/summary");
    } else {
      await router.push("/order/deliveryaddress");
    }
    close();
  };

  const goToShoppingCart = () => {
    router.push("/shoppingcart").then(() => {
      close();
    });
  };

  const itemComponents = useMemo(() => {
    return shoppingCartItems.map((i) => {
      return (
        <Fragment key={i.id}>
          <Text noOfLines={2}>
            {i.count}x {i.item.name}
          </Text>
          <PriceTag
            rootProps={{
              justifyContent: "flex-end",
            }}
            price={i.item.price}
          />
        </Fragment>
      );
    });
  }, [shoppingCartItems]);

  const sum = shoppingCartItems
    .map((i) => i.item.price * i.count)
    .reduce((a, b) => a + b, 0);

  return (
    <Popover
      isLazy
      trigger="click"
      offset={[-100, 8]}
      isOpen={isOpen}
      onClose={close}
    >
      <PopoverTrigger>
        <Text as="span" textAlign="center" position="relative" onClick={open}>
          <FAIcon size="lg" icon={faShoppingCart} />
          {shoppingCartItemCount > 0 && (
            <Box
              position="absolute"
              w={2}
              h={2}
              top={-1}
              right={0}
              bgColor="red.500"
              borderRadius={4}
            />
          )}
        </Text>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">{t("label")}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <SimpleGrid columns={2} spacing={1}>
            {itemComponents}
            {shoppingCartItemCount > 0 && (
              <>
                <Divider my={2} />
                <Divider my={2} />
              </>
            )}
            <Text>{t("sum")}</Text>
            <PriceTag
              price={sum}
              rootProps={{
                justifyContent: "flex-end",
              }}
            />
          </SimpleGrid>
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="space-between">
          <Button onClick={goToShoppingCart}>{t("label")}</Button>
          <Button
            disabled={shoppingCartItemCount === 0}
            onClick={goToDeliveryAddress}
          >
            {t("pay_action")}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

ShoppingCartPopover.Messages = ["components"];

export default ShoppingCartPopover;
