import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import DataReducer from "./dataSlice";
import { OrderProcessReducer } from "./order_process";
import ShoppingCartReducer from "./shoppingCartSlice";
import UserReducer from "./userSlice";
import WishlistReducer from "./wishlistSlice";

const rootReducer = combineReducers({
  shoppingCart: ShoppingCartReducer,
  user: UserReducer,
  orderProcess: OrderProcessReducer,
  data: DataReducer,
  wishlist: WishlistReducer,
});

const persistConfig = {
  key: "hgm-3",
  version: 1,
  storage,
};

type RootState = ReturnType<typeof rootReducer>;
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store, null, () => {
  const userId = store.getState().user.data?.id;
  if (userId && typeof window !== "undefined" && window.document) {
    window.document.cookie = `USER_ID=${userId}; max-age=${60 * 60 * 24 * 365}`;
  }
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
