import { Currency } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initState: Readonly<{
  currency: Currency | null;
}> = Object.freeze({
  currency: {
    id: "6CEC9DBD-5143-4606-93BB-304A63DE7E6A",
    label: "US Dollar",
    symbol: "$",
    code: "USD",
    localeCode: "en",
  },
});

export const dataSlice = createSlice({
  name: "data",
  initialState: initState,
  reducers: {
    updateCurrency: (state, { payload }: PayloadAction<Currency>) => {
      state.currency = payload;
    },
  },
});

export const DataActions = dataSlice.actions;

export default dataSlice.reducer;
