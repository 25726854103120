import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreditCardData, DeliveryMethod, PaymentOption } from "../../../types";
import { DHL } from "../../delivery";
import { CreditCard } from "../../payment";
import { GlobalActions } from "../actions";

export type DeliveryAddress = {
  name: string;
  surname: string;
  email: string;
  create_account: boolean;
  street: string;
  city: string;
  country: string;
};

export type DeliveryAddressFormData = DeliveryAddress & {
  password?: string;
  password_check?: string;
};

export type OrderProcessState = {
  delivery_address?: DeliveryAddress;
  delivery_method?: DeliveryMethod;
  payment_option?: PaymentOption;
  payment_data?: any;
  products?: any;
};

export const MustermannAddress: DeliveryAddressFormData = {
  create_account: false,
  email: "max.mustermann@greenaureus.com",
  name: "Max",
  surname: "Mustermann",
  //id: "67E2E878-61C4-47F6-BFDD-1D21DD22F05F",
  city: "Petersberg",
  country: "Germany",
  //county: "Hessen",
  //houseNumber: "7",
  //postalCode: "36100",
  street: "Akazienweg 7",
  //userId: null,
  //description: null,
};

const MaxMustermannCreditCard: CreditCardData = {
  card_holder_name: "Max",
  card_holder_surname: "Mustermann",
  card_number: "9874 9874 6541 6541",
  currency: "US Dollar (USD)",
  expiration_date_month: "12",
  expiration_date_year: "25",
  security_code: "987",
};

export const initState: OrderProcessState = {};

export const orderProcessSlice = createSlice({
  name: "order_process",
  initialState: initState,
  reducers: {
    addDeliveryAddress: (
      state,
      { payload }: PayloadAction<DeliveryAddressFormData>
    ) => {
      if ("password" in payload) {
        delete payload.password;
        delete payload.password_check;
      }
      state.delivery_address = { ...payload };
    },
    addDeliveryOption: (state, { payload }: PayloadAction<DeliveryMethod>) => {
      state.delivery_method = { ...payload };
    },
    addPaymentOption: (state, { payload }: PayloadAction<PaymentOption>) => {
      state.payment_option = { ...payload };
    },
    addCreditCard: (state, { payload }: PayloadAction<any>) => {
      state.payment_data = { ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GlobalActions.LOGIN, (state) => {
        state.delivery_address = MustermannAddress;
        state.delivery_method = { ...DHL };
        state.payment_option = { ...CreditCard };
        state.payment_data = { ...MaxMustermannCreditCard };
      })
      .addCase(GlobalActions.LOGOUT, (state) => {
        state.delivery_address = undefined;
        state.delivery_method = undefined;
        state.payment_option = undefined;
        state.payment_data = undefined;
      });
  },
});

export const OrderProcessActions = orderProcessSlice.actions;
export const OrderProcessReducer = orderProcessSlice.reducer;
