import {
  Box,
  Divider,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { GlobalActions } from "../../data/redux/actions";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import ShoppingCartPopover from "../cart/ShoppingCartPopover";
import NavLink from "../NavLink";
import SearchBar from "./SearchBar";

function Header() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const hasWishlistItems = useAppSelector((s) => s.wishlist.items.length > 0);
  const userName = useAppSelector((s) =>
    s.user.data.name !== "" ? s.user.data.name : undefined
  );
  const t = useTranslations("components.Header");

  const navigateTo = (route: string) => {
    router.push(route);
  };

  const handleLogout = () => {
    dispatch(GlobalActions.LOGOUT());
    router.push("/");
  };

  return (
    <Flex
      flex={0}
      flexDir="row"
      alignItems="center"
      boxShadow="base"
      paddingY={2}
      paddingX={3}
      gap={3}
      position="fixed"
      background={"white"}
      zIndex="sticky"
      width="100%"
    >
      <Box height="40px" minWidth="40px">
        <NavLink href="/">
          <Image
            src="/assets/hello-green-market-logo.png"
            alt="HelloGreenMarketLogo"
            height="40px"
          />
        </NavLink>
      </Box>
      <Spacer />
      <SearchBar />
      <ShoppingCartPopover />
      <Menu>
        <MenuButton>
          <FAIcon size="lg" icon={faUser} />
        </MenuButton>
        <MenuList>
          {userName && (
            <>
              <MenuItem disabled>Hi {userName}</MenuItem>
              <Divider />
            </>
          )}
          <MenuItem onClick={() => navigateTo("/wishlist")}>
            {t("wishlist")}
            {hasWishlistItems && (
              <Box
                w="6px"
                h="6px"
                borderRadius="full"
                alignSelf="flex-start"
                background={"red"}
              />
            )}
          </MenuItem>
          {userName && (
            <MenuItem onClick={() => navigateTo("/community")}>
              {t("co2_overview")}
            </MenuItem>
          )}
          <MenuItem onClick={() => navigateTo("/community/join")}>
            {t("community")}
          </MenuItem>
          <Divider />
          {userName ? (
            <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
          ) : (
            <MenuItem onClick={() => navigateTo("/auth")}>
              {t("login")}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
}

Header.Messages = ["components"];

export default Header;
