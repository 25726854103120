import {
  HStack,
  StackProps,
  Text,
  TextProps,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useAppSelector } from "../data/redux/hooks";

interface PriceTagProps {
  currency?: string;
  price: number;
  salePrice?: number | null;
  rootProps?: StackProps;
  priceProps?: TextProps;
  salePriceProps?: TextProps;
}

export type FormatPriceOptions = { locale?: string; currency?: string };

export function formatPrice(value: number, opts: FormatPriceOptions = {}) {
  const { locale = "de-DE", currency = "EUR" } = opts;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}

export const PriceTag = (props: PriceTagProps) => {
  const currencyData = useAppSelector((s) => s.data.currency);
  const {
    price,
    currency,
    salePrice = null,
    rootProps,
    priceProps,
    salePriceProps,
  } = props;
  return (
    <HStack spacing="1" {...rootProps}>
      <Price isOnSale={!!salePrice} textProps={priceProps}>
        {formatPrice(price, {
          currency: currency ?? currencyData?.code,
          locale: currencyData?.localeCode,
        })}
      </Price>
      {salePrice && (
        <SalePrice {...salePriceProps}>
          {formatPrice(salePrice, {
            currency: currency ?? currencyData?.code,
            locale: currencyData?.localeCode,
          })}
        </SalePrice>
      )}
    </HStack>
  );
};

interface PriceProps {
  isOnSale?: boolean;
  textProps?: TextProps;
}

const Price = (props: PropsWithChildren<PriceProps>) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = mode("gray.700", "gray.400");
  const onSaleColor = mode("gray.400", "gray.700");
  const color = isOnSale ? onSaleColor : defaultColor;
  return (
    <Text
      as="span"
      fontWeight="medium"
      color={color}
      textDecoration={isOnSale ? "line-through" : "none"}
      {...textProps}
    >
      {children}
    </Text>
  );
};

const SalePrice = (props: TextProps) => (
  <Text
    as="span"
    fontWeight="semibold"
    color={mode("gray.800", "gray.100")}
    {...props}
  />
);
