import type { User } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { GlobalActions } from "./actions";
import { DeliveryAddress, MustermannAddress } from "./order_process";

export const initState: {
  data: Omit<User, "wishlist"> & { wishlist: string[] } & Pick<
      DeliveryAddress,
      "name" | "surname"
    >;
  carbonOffset: {
    monthly: number;
    total: number;
  };
} = {
  data: {
    id: uuidv4(),
    wishlist: [] as string[],
    isAnnonymus: true,
    profileId: null,
    name: "",
    surname: "",
  },
  carbonOffset: {
    monthly: 0,
    total: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    updateCarbonOffset(state, { payload }: PayloadAction<number>) {
      state.carbonOffset.monthly += payload;
      state.carbonOffset.total += payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GlobalActions.LOGIN, (state) => {
        state.data.isAnnonymus = false;
        state.data.id = uuidv4();
        state.data.name = MustermannAddress.name;
        state.data.surname = MustermannAddress.surname;
        state.carbonOffset = {
          monthly: 0,
          total: 2945,
        };
      })
      .addCase(GlobalActions.LOGOUT, (state) => {
        state.data.isAnnonymus = true;
        state.data.id = "";
        state.data.name = "";
        state.data.surname = "";
        state.carbonOffset = {
          monthly: 0,
          total: 0,
        };
      });
  },
});

export const UserActions = userSlice.actions;

export default userSlice.reducer;
