import { Box, VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import Footer from "./Footer";
import Header from "./header/Header";

function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <Header />
      <VStack flex={1} alignItems="stretch">
        <Box
          flex={1}
          //pt={4}
          maxW={{ base: "100%", md: "80%" }}
          mx={{ base: "unset", md: "10%" }}
          mt="56px"
        >
          {children}
        </Box>
        <Footer />
      </VStack>
    </>
  );
}

Layout.Messages = [...Header.Messages, ...Footer.Messages] as string[];

export default Layout;
