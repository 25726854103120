import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, faUniversity } from "@fortawesome/pro-regular-svg-icons";
import { PaymentOption } from "../../types";

export const CreditCard: PaymentOption = {
  icon: faCreditCard,
  id: "E843A5E8-1703-4C88-B9FE-D60062A5F5CC",
  label: {
    en: "Credit card",
    de: "Kreditkarte",
  },
  _path: "credit_card",
};

const PayPal: PaymentOption = {
  id: "91EF3D91-0467-4350-B3E5-69F5EECF9D33",
  label: {
    en: "PayPal",
    de: "Paypal",
  },
  _path: "paypal",
  icon: faPaypal,
  disabled: true,
};

const BankTransfer: PaymentOption = {
  id: "0CFCDAA0-1DEF-43D3-9DA3-6B51CDE58BFC",
  label: {
    en: "Bank transfer",
    de: "Überweisung",
  },
  _path: "bank",
  icon: faUniversity,
  disabled: true,
};

export async function getPaymentOptions(): Promise<PaymentOption[]> {
  return [CreditCard, PayPal, BankTransfer];
}
