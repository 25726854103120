import { DeliveryMethod } from "../../types";

export const DHL: DeliveryMethod = {
  description: {
    de: "Wir setzen alles daran, dass unsere Arbeit die Umwelt so wenig wie möglich belastet. Helfen Sie uns, für eine bessere Zukunft zu kämpfen, indem Sie sich für eine CO2-neutrale Lieferung entscheiden:",
    en: "We make every effort to ensure that our work has as little impact on the environment as possible. Help us fight for a better tomorrow by choosing CO2 Neutral Delivery:",
  },
  description_short: {
    en: "Delivery possible tomorrow!",
    de: "Lieferung morgen möglich!",
  },
  fee: 3.9,
  icon: "/assets/dhl_logo.svg",
  id: "B728A878-F6EC-4508-A652-EE293CE4C38E",
  label: "DHL",
};

const Amazon: DeliveryMethod = {
  description: {
    en: "",
    de: "",
  },
  description_short: {
    en: "Delivery possible tomorrow!",
    de: "Lieferung morgen möglich!",
  },
  fee: 4.9,
  icon: "/assets/amazon_logo.png",
  id: "BC84E689-41C2-4EC4-B7B9-06D3B3BCB690",
  label: "Amazon",
};

const UPS: DeliveryMethod = {
  description: {
    en: "",
    de: "",
  },
  description_short: {
    en: "Delivery possible tomorrow!",
    de: "Lieferung morgen möglich!",
  },
  fee: 5,
  icon: "/assets/ups_logo.svg",
  id: "EAB9B179-E698-4271-8942-9E0F5EBCDB99",
  label: "UPS",
};

export async function getDeliveryMethods(): Promise<DeliveryMethod[]> {
  return [DHL, Amazon, UPS];
}
