import { createContext } from "@chakra-ui/react-utils";

export const basePageTitle = "Hello Green Market";

export type PageContextType = {
  update: (title?: string) => void;
  addToBase: (value: string) => void;
};
export const [PageTitleContextProvider, usePageTitleContext, PageTitleContext] =
  createContext<PageContextType>({
    name: "PageTitleContext",
  });
