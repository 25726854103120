import { Box, HStack, Text } from "@chakra-ui/react";
import P from "../../package.json";

function Footer() {
  return (
    <Box as="footer" width="100%" px={2}>
      <HStack justifyContent="center" alignItems="flex-end">
        <Text fontSize="sm">Hello Green Market</Text>
        <Text fontSize="xs">{P.version}</Text>
      </HStack>
    </Box>
  );
}

Footer.Messages = ["components"];

export default Footer;
