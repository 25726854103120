import { ChakraProvider, ColorModeProvider } from "@chakra-ui/react";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { IntlErrorCode, NextIntlProvider } from "next-intl";
import { AppProps } from "next/app";
import Head from "next/head";
import { ComponentProps } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Layout from "../src/components/Layout";
import store, { persistor } from "../src/data/redux";
import { basePageTitle } from "../src/util/PageTitleContext";
import { theme } from "../src/util/theme";
import "../styles/globals.css";

config.autoAddCss = false;

const getMessageFallback: ComponentProps<
  typeof NextIntlProvider
>["getMessageFallback"] = ({ namespace, key, error }) => {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return `${path} is not yet translated`;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
};

type MyAppProps = AppProps & {};

function MyApp({
  Component,
  pageProps: { messages, ...restPageProps },
}: MyAppProps) {
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider resetCSS theme={theme}>
          <ColorModeProvider
            options={{
              initialColorMode: "light",
              useSystemColorMode: false,
            }}
          >
            <NextIntlProvider
              getMessageFallback={getMessageFallback}
              messages={messages}
            >
              <Head>
                <title>
                  {process.env.NODE_ENV === "development" ? "(DEV) " : ""}
                  {basePageTitle}
                </title>
                <link rel="icon" href={"/favicon.ico"} />
              </Head>
              <Layout>
                <Component {...restPageProps} />
              </Layout>
            </NextIntlProvider>
          </ColorModeProvider>
        </ChakraProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

// noinspection JSUnusedGlobalSymbols
export default MyApp;
