import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ShoppingCartItem } from "../../types";

export const initState: Readonly<{
  items: ShoppingCartItem[];
}> = Object.freeze({ items: [] });

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: initState,
  reducers: {
    addShoppingCartItem: (
      state,
      { payload }: PayloadAction<ShoppingCartItem["item"]>
    ) => {
      const current = state.items.find((i) => i.id === payload.id);
      if (current) {
        current.count++;
      } else {
        state.items.push({ item: payload, count: 1, id: payload.id });
      }
    },
    updateShoppingCartItemCount: (
      state,
      {
        payload: { id, count },
      }: PayloadAction<{ id: string; count: 1 | -1 | number }>
    ) => {
      const itemIndex = state.items.findIndex((i) => i.id === id);
      const item = state.items[itemIndex];
      if (item) {
        item.count = item.count + count;
        if (item.count <= 0) {
          state.items.splice(itemIndex, 1);
        }
      }
    },
    deleteShoppingCartItem: (state, { payload: id }: PayloadAction<string>) => {
      const itemIndex = state.items.findIndex((i) => i.id === id);
      if (itemIndex > -1) {
        state.items.splice(itemIndex, 1);
      }
    },
    clearShoppingCartItems: (state) => {
      state.items = [];
    },
  },
});

export const {
  addShoppingCartItem,
  updateShoppingCartItemCount,
  deleteShoppingCartItem,
  clearShoppingCartItems,
} = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
