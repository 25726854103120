import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useBreakpointValue,
  useControllableState,
} from "@chakra-ui/react";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import NavLink from "../NavLink";

function SearchBar() {
  const router = useRouter();
  const showSearchInput = useBreakpointValue({ base: false, md: true });
  const [value, onChange] = useControllableState({ defaultValue: "" });

  const onSearch = () => {
    if (value.length > 1) {
      router.push({
        pathname: "/search",
        query: {
          search: value,
        },
      });
    }
  };

  if (showSearchInput) {
    return (
      <InputGroup maxW="50%" size="sm">
        <Input
          value={value}
          minLength={2}
          onChange={(e) => onChange(e.target.value)}
        />
        <InputRightElement>
          <IconButton
            aria-label="Search"
            icon={<FAIcon icon={faSearch} />}
            onClick={onSearch}
            size="sm"
          />
        </InputRightElement>
      </InputGroup>
    );
  }

  return (
    <>
      <NavLink
        label="Search"
        icon={<FAIcon size="lg" icon={faSearch} />}
        href="/search"
      />
    </>
  );
}

export default SearchBar;
