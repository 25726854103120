import { extendTheme } from "@chakra-ui/react";
import Button from "./components/button";
import Text from "./components/text";

export const theme = extendTheme({
  colors: {
    primary: {
      50: "#c3fce3",
      100: "#9af9d0",
      200: "#72f7be",
      300: "#4af5ab",
      400: "#21f399",
      500: "#0cde83",
      600: "#0ab56b",
      700: "#088d54",
      800: "#06653c",
      900: "#033c24",
    },
  },
  shadows: {
    "full-sm": "0 0 8px 1px rgba(0, 0, 0, 0.1)",
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "30px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "60px",
    "7xl": "72px",
    "8xl": "96px",
    "9xl": "128px",
  },
  components: {
    Button,
    Text,
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
    cssVarPrefix: "hgm",
  },
});
